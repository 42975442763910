import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { Component } from 'react'

export default class EstateCard extends Component<{ src: string, name: string, about: any }> {

    render() {
        return (
            <div className="estate-card">

                <Link to={"/hyresrätter/"+this.props.name.toLowerCase().split(" ").join("-")} className="estate-card__thumbnail">
                    <div className="estate-card__thumbnail__image" style={{
                        backgroundImage: `url(${this.props.src})`,
                        backgroundSize: "cover",
                        backgroundPosition:"0px"
                    }}>
                    </div>
                </Link>
                
                <h2>{this.props.name}</h2>
                
                <p>{this.props.about.substr(0, 160)}...</p>
                
                <Link to={"/hyresrätter/"+this.props.name.toLowerCase().split(" ").join("-")}>Läs mer</Link>

            </div>
        )
    }
}
